<template>
  <div class="manage-repayment" :class="{skeleton: !state.loaded}">
    <template v-if="state.schedules">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>지급일</th>
            <th>상환금액</th>
            <th>이자 지급액</th>
            <th>합계</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(schedule, index) in state.schedules" :key="index">
            <td>
              <span>{{ schedule.repaymentDate }}</span>
            </td>
            <td>
              <span>{{ formatCurrency(schedule.repaymentAmount) }}</span>
            </td>
            <td>
              <span>{{ formatCurrency(schedule.interestAmount) }}</span>
            </td>
            <td>
              <span>{{ formatCurrency(schedule.totalAmount) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <NoData v-else/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import NoData from "@/components/NoData";
import http from "@/scripts/http";
import {httpError} from "@/scripts/httpError";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "pageManageRepayment";
  this.initialize = initialize;
}

export default defineComponent({
  components: { NoData },
  mixins: [mixin],
  props: {
    project: Object
  },
  setup() {
    onMounted(() => {
      load();
    });

    const component = new Component(() => {
      for (let i = 0; i < 5; i += 1) {
        state.schedules.push({
          repaymentDate: "2000-00-00",
          repaymentAmount: "1,000,000,000",
          interestAmount: "1,000,000",
          totalAmount: "1,000,000,000",
        });
      }
    });

    const state = reactive({
      loaded: false,
      schedules: [],
    });

    const load = () => {
      state.loaded = false;
      http.get(`/api/project/invest/${router.app.$route.params.projectSeq}/repayment`).then(({data}) => {
        state.loaded = true;
        state.schedules  = data.body;
      }).catch(httpError(() => {
      }));
    };

    const formatCurrency = (value) => {
      const number = parseFloat(value);
      return !isNaN(number) ? number.toLocaleString() : "(N/A)";
    };

    return { component, state, load, formatCurrency };
  }
});
</script>

<style lang="scss" scoped>
.manage-repayment {
  &.skeleton {
    table {
      th, td {
        > span {
          @include skeleton;
        }
      }
    }
  }
}
</style>
